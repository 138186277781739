import { Component } from 'vue'
import { RouteRecordRaw } from 'vue-router'

import RouteView from '@/layouts/routeView.vue'

export const Statistics: RouteRecordRaw = {
  path: 'statistics',
  name: 'manage-statistics',
  meta: { title: '数据统计', role: 'Admin.Charts', icon: 'oa_statistics', fullPath: '/manage/statistics' },
  component: RouteView,
  redirect: '/manage/statistics/index',
  children: [
    {
      path: 'index',
      name: 'manage-statistics-index',
      meta: { icon: 'oa_statistics', title: '数据统计', hideInMenu: true, fullPath: '/manage/statistics/index' },
      component: (): Component => import('@/views/manage/statistics/index.vue')
    },

    {
      path: 'usertype',
      name: 'manage-statistics-usertype',
      meta: { icon: 'oa_statistics_cir', role: 'Admin.Charts', title: '用户类别', fullPath: '/manage/statistics/usertype' },
      component: (): Component => import('@/views/manage/statistics/userType.vue')
    },
    {
      path: 'userentry',
      name: 'manage-statistics-userentry',
      meta: { icon: 'oa_chart_entry', role: 'Admin.Charts', title: '入职统计', fullPath: '/manage/statistics/userentry' },
      component: (): Component => import('@/views/manage/statistics/userEntry.vue')
    },
    {
      path: 'userleave',
      name: 'manage-statistics-userleave',
      meta: { icon: 'oa_chart_leave', role: 'Admin.Charts', title: '离职统计', fullPath: '/manage/statistics/userleave' },
      component: (): Component => import('@/views/manage/statistics/userLeave.vue')
    },
    {
      path: 'userearnings',
      name: 'manage-statistics-userearnings',
      meta: { icon: 'oa_chart_payment', role: 'Admin.Charts', title: '用户台账', fullPath: '/manage/statistics/userearnings' },
      component: (): Component => import('@/views/manage/statistics/userEarnings.vue')
    },
    {
      path: 'caseearnings',
      name: 'manage-statistics-caseearnings',
      meta: { icon: 'oa_chart_payment', role: 'Admin.Charts', title: '案件台账', fullPath: '/manage/statistics/caseearnings' },
      component: (): Component => import('@/views/manage/statistics/caseEarnings.vue')
    },
    {
      path: 'userinvioce',
      name: 'manage-statistics-userinvioce',
      meta: { icon: 'oa_invoice', role: 'Admin.Charts', title: '用户创收', fullPath: '/manage/statistics/userinvioce' },
      component: (): Component => import('@/views/manage/statistics/userInvioce.vue')
    },
    {
      path: 'businessDepartment',
      name: 'manage-statistics-businessDepartment',
      meta: { icon: 'oa_chart_payment', role: 'Admin.Charts', title: '业务部门', fullPath: '/manage/statistics/businessDepartment' },
      component: (): Component => import('@/views/manage/statistics/businessDepartment.vue')
    },
    {
      path: 'client',
      name: 'manage-statistics-client',
      meta: { icon: 'oa_client', role: 'Admin.Charts', title: '客户统计', fullPath: '/manage/statistics/client' },
      component: (): Component => import('@/views/manage/statistics/client/index.vue')
    },

    {
      path: 'lawfirm',
      name: 'namage-statistics-lawfirm',
      meta: { title: '律所统计', role: 'Admin.Charts', icon: 'oa_lawfirm', fullPath: '/manage/statistics/lawfirm' },
      component: RouteView,
      redirect: '/manage/statistics/lawfirmearnings',
      children: [
        {
          path: 'lawfirmearnings',
          name: 'manage-statistics-lawfirm-lawfirmearnings',
          meta: { icon: 'oa_lawfirm_invoice', role: 'Admin.Charts', title: '律所创收', fullPath: '/manage/statistics/lawfirm/lawfirmearnings' },
          component: (): Component => import('@/views/manage/statistics/lawfirm/lawfirmEarnings.vue')
        },
        {
          path: 'lawfirminvoice',
          name: 'manage-statistics-lawfirm-lawfirminvoice',
          meta: { icon: 'oa_lawfirm_invoice', role: 'Admin.Charts', title: '律所创收(图)', fullPath: '/manage/statistics/lawfirm/lawfirminvoice' },
          component: (): Component => import('@/views/manage/statistics/lawfirm/lawfirmInvoice.vue')
        },
        {
          path: 'usercount',
          name: 'manage-statistics-lawfirm-usercount',
          meta: { icon: 'oa_team', role: 'Admin.Charts', title: '人员数量', fullPath: '/manage/statistics/lawfirm/usercount' },
          component: (): Component => import('@/views/manage/statistics/lawfirm/userCount.vue')
        },
        // {
        //   path: 'userearnings',
        //   name: 'manage-statistics-lawfirm-userearnings',
        //   meta: { icon: 'oa_chart_payment', role: 'statistics.lawfirm.userearning', title: '用户台账', fullPath: '/manage/statistics/lawfirm/userearnings' },
        //   component: (): Component => import('@/views/manage/statistics/lawfirm/userEarnings.vue')
        // },
        {
          path: 'userinvioce',
          name: 'manage-statistics-lawfirm-userinvioce',
          meta: { icon: 'oa_chart_payment', role: 'Admin.Charts', title: '用户创收', fullPath: '/manage/statistics/lawfirm/userinvioce' },
          component: (): Component => import('@/views/manage/statistics/lawfirm/userInvioce.vue')
        }
      ]
    },
    {
      path: 'project',
      name: 'namage-statistics-project',
      meta: { title: '项目分析', role: 'Admin.Charts', icon: 'oa_yewuguanli', fullPath: '/manage/statistics/project' },
      component: RouteView,
      redirect: '/manage/statistics/project/lawfirmearnings',
      children: [
        {
          path: 'industry',
          name: 'manage-statistics-project-industry',
          meta: { icon: 'oa_hangye', title: '行业', fullPath: '/manage/statistics/project/industry' },
          component: (): Component => import('@/views/manage/statistics/project/industry.vue')
        },
        {
          path: 'businessarea',
          name: 'manage-statistics-project-businessarea',
          meta: { icon: 'oa_statistics_cir', title: '领域', fullPath: '/manage/statistics/project/businessarea' },
          component: (): Component => import('@/views/manage/statistics/project/businessarea.vue')
        },
        {
          path: 'caseReason',
          name: 'manage-statistics-project-caseReason',
          meta: { icon: 'oa_statistics_cir', title: '案由', fullPath: '/manage/statistics/project/caseReason' },
          component: (): Component => import('@/views/manage/statistics/project/caseReason.vue')
        },
        {
          path: 'court',
          name: 'manage-statistics-project-court',
          meta: { icon: 'oa_court', title: '法院', fullPath: '/manage/statistics/project/court' },
          component: (): Component => import('@/views/manage/statistics/project/court.vue')
        },
        {
          path: 'businessDepartment',
          name: 'manage-statistics-project-businessDepartment',
          meta: { icon: 'oa_statistics_cir', title: '业务部门', hideInMenu: true, fullPath: '/manage/statistics/project/businessDepartment' },
          component: (): Component => import('@/views/manage/statistics/project/businessDepartment.vue')
        },
        {
          path: 'clientType',
          name: 'manage-statistics-project-clientType',
          meta: { icon: 'oa_leibie', title: '客户类别', fullPath: '/manage/statistics/project/clientType' },
          component: (): Component => import('@/views/manage/statistics/project/clientType.vue')
        },
        {
          path: 'projectType',
          name: 'manage-statistics-project-projectType',
          meta: { icon: 'oa_statistics_cir', title: '项目类型', fullPath: '/manage/statistics/project/projectType' },
          component: (): Component => import('@/views/manage/statistics/project/projectType.vue')
        },
        {
          path: 'caseType',
          name: 'manage-statistics-project-caseType',
          meta: { icon: 'oa_statistics_cir', title: '案件类别', fullPath: '/manage/statistics/project/caseType' },
          component: (): Component => import('@/views/manage/statistics/project/caseType.vue')
        },
        {
          path: 'projectStatus',
          name: 'manage-statistics-project-projectStatus',
          meta: { icon: 'oa_statistics_cir', title: '项目状态', fullPath: '/manage/statistics/project/projectStatus' },
          component: (): Component => import('@/views/manage/statistics/project/projectStatus.vue')
        }
      ]
    }
  ]
}
